<template>
  <main class="min-h-screen bg-top bg-cover sm:bg-top">
    <div class="px-4 py-16 mx-auto text-center max-w-7xl sm:px-6 sm:py-24 lg:px-8 lg:py-48">
      <h1 class="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">{{ title }}</h1>
      <p class="mt-2 text-lg text-black" v-if="subtitle">{{ subtitle }}</p>
      <div class="mt-6">
        <router-link :to="{ name: 'home' }">
          <button type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-white border rounded-md shadow-sm bg-pol-red hover:bg-pol-red-dark">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
            </svg>
            Back to search
          </button>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Parcel',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    }
  },
  methods: {
    hasLocation () {
      if (!this.version.location) {
        return false
      }

      if (this.version.location.latitude && this.version.location.longitude) {
        return true
      }

      return false
    },
    getGoogleMapsLink () {
      return `https://maps.google.com/?q=${this.version.location.latitude},${this.version.location.longitude}`
    }
  }
}
</script>
